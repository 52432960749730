.wakana_about_main_visual {
  background: url("/images/wakana_about/wakana_about_main_visual-bgimg.png") no-repeat center/cover;
  position: relative;
}

.wakana_about_main_visual:before {
  content: '';
  display: block;
  padding-top: 33.67%;
}

@media (max-width: 428px) {
  .wakana_about_main_visual {
    height: 530px;
    background-position: right;
  }
}

.wakana_about_main_visual--inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.wakana_about_main_visual--ttl {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.2em;
  margin: 0 0 24px;
}

@media (max-width: 428px) {
  .wakana_about_main_visual--ttl {
    font-size: 22px;
    line-height: 44px;
    letter-spacing: 0.05em;
  }
}

.wakana_about_main_visual--description {
  color: #fff;
  font-size: 16px;
  line-height: 2.375;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 40px;
}

@media (max-width: 428px) {
  .wakana_about_main_visual--description {
    font-size: 14px;
    line-height: 28px;
    max-width: 300px;
    margin: 0 auto 56px;
  }
}

.wakana_about_main_visual--links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
}

@media (max-width: 428px) {
  .wakana_about_main_visual--links {
    flex-direction: column;
    gap: 0;
    bottom: -156px;
  }
}

.wakana_about_main_visual--links-for_toppage {
  position: static;
  background-color: #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wakana_about_main_visual--links-static_mb40 {
  position: static;
  margin-bottom: 40px;
}

.wakana_about_main_visual--link {
  transition: 0.2s;
}

.wakana_about_main_visual--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana_about_main_visual--link {
    max-width: 240px;
  }
}

.wakana_about_main_visual--link_img {
  max-width: 100%;
}

.wakana_about {
  padding-top: 170px;
  padding-bottom: 90px;
}

@media (max-width: 428px) {
  .wakana_about {
    padding: 50px 20px;
    padding-top: 224px;
  }
}

.wakana_about--ttl {
  margin: 0 0 70px;
  text-align: center;
}

@media (max-width: 428px) {
  .wakana_about--ttl {
    margin-bottom: 30px;
  }
}

.wakana_about--ttl_img {
  max-width: 100%;
  width: 388px;
}

@media (max-width: 428px) {
  .wakana_about--ttl_img {
    width: 240px;
  }
}

.wakana_about_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1060px;
  margin: 0 auto 66px;
}

.wakana_about_content.second {
  flex-direction: row-reverse;
}

.wakana_about_content.fourth {
  flex-direction: row-reverse;
}

.wakana_about_content.fifth {
  margin-bottom: 0;
}

@media (max-width: 428px) {
  .wakana_about_content {
    display: block;
    margin-bottom: 50px;
  }
  .wakana_about_content:last-child {
    margin-bottom: 0;
  }
}

.wakana_about_content--img_box {
  width: 520px;
}

@media (max-width: 428px) {
  .wakana_about_content--img_box {
    width: auto;
    margin-bottom: 30px;
  }
}

.wakana_about_content--box {
  width: calc(1060px - 520px);
  padding-left: 60px;
  padding-right: 20px;
}

.first .wakana_about_content--box {
  padding-top: 100px;
}

.second .wakana_about_content--box {
  padding-top: 30px;
}

.third .wakana_about_content--box {
  padding-top: 30px;
}

.fourth .wakana_about_content--box {
  padding-top: 45px;
}

.fifth .wakana_about_content--box {
  padding-top: 135px;
}

@media (max-width: 428px) {
  .wakana_about_content--box {
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .first .wakana_about_content--box {
    padding-top: 0;
  }
  .second .wakana_about_content--box {
    padding-top: 0;
  }
  .third .wakana_about_content--box {
    padding-top: 0;
  }
  .fourth .wakana_about_content--box {
    padding-top: 0;
  }
  .fifth .wakana_about_content--box {
    padding-top: 0;
  }
}

.wakana_about_content--img {
  max-width: 100%;
}

.wakana_about_content--ttl {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 2;
  margin: 0 0 40px;
  letter-spacing: 0.12em;
}

@media (max-width: 428px) {
  .wakana_about_content--ttl {
    line-height: 1.6;
    margin-bottom: 25px;
    text-align: center;
    letter-spacing: 0.05em;
  }
}

.wakana_about_content--description {
  font-size: 16px;
  line-height: 2.375;
}

@media (max-width: 428px) {
  .wakana_about_content--description {
    font-size: 15px;
    line-height: 28px;
  }
}

.wakana_about_content--modal_link {
  color: #4b94af;
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  max-width: 380px;
  line-height: 1.5;
  padding: 18px 0;
  border: 1px solid #4b94af;
  border-radius: 5px;
  margin: 32px auto 0;
  position: relative;
  transition: 0.2s;
}

.wakana_about_content--modal_link:hover {
  opacity: 0.8;
}

.wakana_about_content--modal_link:before {
  position: absolute;
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-top: 1px solid #4b94af;
  border-right: 1px solid #4b94af;
  transform: rotate(45deg) translateY(-50%);
  top: calc(50%);
  right: 20px;
}

@media (max-width: 428px) {
  .wakana_about_content--modal_link {
    width: auto;
    max-width: 325px;
    margin-top: 20px;
  }
}

.wakana_about_content_modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.75);
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  pointer-events: none;
  transform: scale(0);
  visibility: hidden;
  transition: 0.2s;
}

.wakana_about_content_modal.js-show {
  visibility: visible;
  transform: scale(1);
  pointer-events: auto;
}

.wakana_about_content_modal--inner {
  background-color: #fff;
  max-width: 750px;
  padding: 20px;
  margin: 0 0 30px;
}

@media (max-width: 428px) {
  .wakana_about_content_modal--inner {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.wakana_about_content_modal--ttl {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.12em;
  margin: 0 0 36px;
  text-align: center;
}

@media (max-width: 428px) {
  .wakana_about_content_modal--ttl {
    font-size: 15px;
    line-height: 1.5;
  }
}

.wakana_about_content_modal--img {
  max-width: 100%;
  display: block;
  margin: 0 auto 24px;
}

.wakana_about_content_modal--description {
  font-size: 16px;
  line-height: 1.625;
}

@media (max-width: 428px) {
  .wakana_about_content_modal--description {
    font-size: 15px;
  }
}

.wakana_about_content_modal--close_btn {
  font-size: 12px;
  color: #fff;
  background-color: #333333;
  width: 60px;
  height: 60px;
  position: relative;
  padding-top: 40px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
}

.wakana_about_content_modal--close_btn:hover {
  opacity: 0.8;
}

.wakana_about_content_modal--close_btn:before, .wakana_about_content_modal--close_btn:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 28px;
  transform-origin: center;
  background: #fff;
  left: 50%;
  top: calc(50% - 20px);
}

.wakana_about_content_modal--close_btn:before {
  transform: rotate(45deg) translate(-50%);
}

.wakana_about_content_modal--close_btn:after {
  transform: rotate(-45deg);
}

.wakana-contact_area {
  background-color: #d8d8d8;
  position: relative;
  padding: 40px 0;
}

.wakana-contact_area:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: calc(50% - 1px);
  top: 0;
  background-color: #fff;
}

@media (max-width: 428px) {
  .wakana-contact_area {
    padding: 30px 20px;
  }
  .wakana-contact_area:before {
    display: none;
  }
}

.wakana-contact_area--inner {
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

@media (max-width: 428px) {
  .wakana-contact_area--inner {
    display: block;
  }
}

.wakana-contact_area--box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 70px);
}

@media (max-width: 428px) {
  .wakana-contact_area--box {
    width: auto;
    display: block;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
  .wakana-contact_area--box:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.wakana-contact_area--lead {
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  margin: 0 0 10px;
}

@media (max-width: 428px) {
  .wakana-contact_area--lead {
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.wakana-contact_area--tel {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  margin: 0 0 8px;
}

@media (max-width: 428px) {
  .wakana-contact_area--tel {
    text-align: center;
  }
}

.wakana-contact_area--small {
  display: block;
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin: 0 0 24px;
}

.wakana-contact_area--small:last-child {
  margin-bottom: 0;
}

@media (max-width: 428px) {
  .wakana-contact_area--small {
    font-size: 12px;
    text-align: center;
  }
}

.wakana-contact_area--link_flow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  color: #6692ab;
  background-color: #fff;
  border: 1px solid #6692ab;
}

.wakana-contact_area--link_flow:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #6692ab;
  border-right: 1px solid #6692ab;
}

.wakana-contact_area--link_flow:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact_area--link_flow {
    margin: 0 auto;
  }
}

.wakana-contact_area--link_book {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  color: #fff;
  background-color: #6692ab;
}

.wakana-contact_area--link_book:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.wakana-contact_area--link_book:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact_area--link_book {
    margin: 0 auto;
  }
}

.wakana-contact_area--annotate {
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin: 0 0 18px;
}

@media (max-width: 428px) {
  .wakana-contact_area--annotate {
    line-height: 1.42;
    margin-bottom: 10px;
  }
}

.wakana-contact {
  background-color: #d8d8d8;
  padding: 40px 0;
  border-top: 2px solid #fff;
}

@media (max-width: 428px) {
  .wakana-contact {
    padding: 30px 20px;
    border-top: none;
    position: relative;
  }
  .wakana-contact:before {
    position: absolute;
    content: '';
    display: block;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #fff;
    left: 20px;
    top: 0;
  }
}

.wakana-contact--ttl {
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  letter-spacing: 0.15em;
  text-align: center;
  font-weight: normal;
}

.wakana-contact--inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 40px;
  margin-top: 20px;
}

@media (max-width: 428px) {
  .wakana-contact--inner {
    display: block;
    margin-top: 10px;
  }
}

.wakana-contact--content {
  min-width: 260px;
}

@media (max-width: 428px) {
  .wakana-contact--content {
    min-width: inherit;
  }
}

.wakana-contact--tel {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
}

@media (max-width: 428px) {
  .wakana-contact--tel {
    text-align: center;
  }
}

.wakana-contact--business_hour {
  font-family: "Noto Serif JP", serif;
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 428px) {
  .wakana-contact--business_hour {
    text-align: center;
  }
}

.wakana-contact--link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  height: 48px;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 14px;
  margin-top: auto;
  background-color: #fff;
  color: #333;
}

.wakana-contact--link:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

.wakana-contact--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-contact--link {
    margin-top: 24px;
  }
}

.wakana-think {
  padding: 110px 0 40px;
  position: relative;
  z-index: 0;
}

@media (max-width: 428px) {
  .wakana-think {
    padding: 50px 20px 20px;
    overflow: hidden;
  }
}

.wakana-think--ttl {
  display: block;
  position: relative;
  margin: 0 0 120px;
}

.wakana-think--ttl:before, .wakana-think--ttl:after {
  display: block;
  position: absolute;
  content: url("/images/wakana_about/wakana-think--ttl-bgimg.png");
  z-index: 1;
}

.wakana-think--ttl:before {
  left: calc(50% - 380px);
  top: -120px;
}

.wakana-think--ttl:after {
  left: calc(50% + 150px);
  top: -50px;
  transform: rotate(180deg);
}

@media (max-width: 428px) {
  .wakana-think--ttl {
    margin-bottom: 60px;
  }
  .wakana-think--ttl:before {
    left: calc(50% - 340px);
  }
  .wakana-think--ttl:after {
    left: calc(50% + 100px);
  }
}

.wakana-think--ttl_txt {
  color: #4b94af;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.2em;
  display: block;
  margin: 0 auto 16px;
  text-align: center;
  position: relative;
  z-index: 2;
}

@media (max-width: 428px) {
  .wakana-think--ttl_txt {
    font-size: 22px;
    letter-spacing: 0.05em;
  }
}

.wakana-think--ttl_lead {
  color: #4b94af;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wakana-think--ttl_lead:before, .wakana-think--ttl_lead:after {
  content: '';
  display: block;
  width: 22px;
  height: 1px;
  background-color: #4b94af;
  margin: 0 15px;
}

@media (max-width: 428px) {
  .wakana-think--ttl_lead {
    font-size: 15px;
  }
  .wakana-think--ttl_lead:before, .wakana-think--ttl_lead:after {
    margin: 0 10px;
  }
}

.wakana-think--review {
  font-size: 16px;
  line-height: 2.375;
  font-feature-settings: "palt";
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 0 40px 20px;
  border: 1px solid #9dc8d0;
  position: relative;
  z-index: 2;
}

.wakana-think--review:before, .wakana-think--review:after {
  position: absolute;
  content: '';
  display: block;
  width: 3px;
  height: 16px;
  background-color: #fff;
}

.wakana-think--review:before {
  bottom: 0;
  left: -1px;
}

.wakana-think--review:after {
  top: 0;
  right: -1px;
}

@media (max-width: 428px) {
  .wakana-think--review {
    padding: 20px;
    font-size: 15px;
    line-height: 28px;
  }
}

.wakana-think_content {
  max-width: 980px;
  margin: 0 auto 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.wakana-think_content:nth-of-type(1) {
  padding-bottom: 40px;
  border-bottom: 1px solid #c5c5c5;
}

.wakana-think_content:nth-of-type(even) {
  flex-direction: row-reverse;
}

.wakana-think_content:nth-of-type(even):before {
  left: -200px;
}

.wakana-think_content:nth-of-type(odd):before {
  right: -200px;
}

.wakana-think_content:nth-of-type(n+2):before {
  position: absolute;
  content: url("/images/wakana_about/wakana-think_content-bgimg.png");
  top: -300px;
  z-index: 1;
}

@media (max-width: 428px) {
  .wakana-think_content {
    display: block;
    margin-bottom: 50px;
  }
  .wakana-think_content:nth-of-type(1) {
    padding-bottom: 20px;
  }
  .wakana-think_content:last-child {
    margin-bottom: 0;
  }
  .wakana-think_content:before {
    display: none;
  }
}

.wakana-think_content--box {
  width: calc(50% - 30px);
  position: relative;
  z-index: 2;
}

@media (max-width: 428px) {
  .wakana-think_content--box {
    width: auto;
    margin-bottom: 30px;
  }
  .wakana-think_content--box:last-child {
    margin-bottom: 0;
  }
}

.wakana-think_content--img {
  max-width: 100%;
}

.wakana-think_content--comment {
  color: #868686;
  font-size: 14px;
  margin-top: 10px;
}

.wakana-think_content--ttl {
  color: #4b94af;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 2;
  letter-spacing: 0.12em;
  margin: 0 0 30px;
  position: relative;
}

.wakana-think_content--ttl:before {
  position: absolute;
  content: url("/images/wakana_about/wakana-think_content--ttl-icon.png");
  left: -38px;
  top: -20px;
}

@media (max-width: 428px) {
  .wakana-think_content--ttl {
    margin-bottom: 20px;
    letter-spacing: 0.05em;
  }
}

.wakana-think_content--description {
  font-size: 16px;
  line-height: 2.375;
}

.wakana-think_content--description-nottl {
  margin-top: 68px;
}

@media (max-width: 428px) {
  .wakana-think_content--description {
    font-size: 15px;
    line-height: 28px;
  }
  .wakana-think_content--description-nottl {
    margin-top: 0;
  }
}

.wakana-about_present {
  display: flex;
  justify-content: center;
  background: url("/images/wakana_about/wakana-about_present-border.png") no-repeat center bottom;
  padding-bottom: 40px;
  margin-bottom: 90px;
}

@media (max-width: 428px) {
  .wakana-about_present {
    margin-bottom: 40px;
  }
}

.wakana-faq_button {
  margin: 0 auto;
  padding: 68px 0 0;
}

@media (max-width: 428px) {
  .wakana-faq_button {
    padding: 40px 20px 0;
  }
}

.wakana-faq_button--link {
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  width: 368px;
  height: 68px;
  border: 1px solid;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  font-size: 18px;
  background-color: #fff;
  color: #333;
  margin: 0 auto;
}

.wakana-faq_button--link:after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

.wakana-faq_button--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .wakana-faq_button--link {
    width: 100%;
  }
}
