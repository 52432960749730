@charset "UTF-8";
@import "../mixin";

.wakana_about_main_visual {
  background: url('/images/wakana_about/wakana_about_main_visual-bgimg.png') no-repeat center/cover;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 33.67%;
  }
  @include sp {
    height: 530px;
    background-position: right;
  }
  &--inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  &--ttl {
    color: #fff;
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    line-height: 2;
    letter-spacing: 0.2em;
    margin: 0 0 24px;
    @include sp {
      font-size: 22px;
      line-height: 44px;
      letter-spacing: 0.05em;
    }
  }
  &--description {
    color: #fff;
    font-size: 16px;
    line-height: 2.375;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      line-height: 28px;
      max-width: 300px;
      margin: 0 auto 56px;
    }
  }
  &--links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 64px;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    @include sp {
      flex-direction: column;
      gap: 0;
      bottom: -156px;
    }
    &-for_toppage {
      position: static;
      background-color: #f3f3f3;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-static_mb40 {
      position: static;
      margin-bottom: 40px;
    }
  }
  &--link {
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      max-width: 240px;
    }
  }
  &--link_img {
    max-width: 100%;
  }
}
.wakana_about {
  padding-top: 170px;
  padding-bottom: 90px;
  @include sp {
    padding: 50px 20px;
    padding-top: 224px;
  }
  &--ttl {
    margin: 0 0 70px;
    text-align: center;
    @include sp {
      margin-bottom: 30px;
    }
  }
  &--ttl_img {
    max-width: 100%;
    width: 388px;
    @include sp {
      width: 240px;
    }
  }
}
.wakana_about_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1060px;
  margin: 0 auto 66px;
  &.first {
  }
  &.second {
    flex-direction: row-reverse;
  }
  &.third {
  }
  &.fourth {
    flex-direction: row-reverse;
  }
  &.fifth {
    margin-bottom: 0;
  }
  @include sp {
    display: block;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--img_box {
    width: 520px;
    @include sp {
      width: auto;
      margin-bottom: 30px;
    }
  }
  &--box {
    width: calc(1060px - 520px);
    padding-left: 60px;
    padding-right: 20px;
    .first & {
      padding-top: 100px;
    }
    .second & {
      padding-top: 30px;
    }
    .third & {
      padding-top: 30px;
    }
    .fourth & {
      padding-top: 45px;
    }
    .fifth & {
      padding-top: 135px;
    }
    @include sp {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      .first & {
        padding-top: 0;
      }
      .second & {
        padding-top: 0;
      }
      .third & {
        padding-top: 0;
      }
      .fourth & {
        padding-top: 0;
      }
      .fifth & {
        padding-top: 0;
      }
    }
  }
  &--img {
    max-width: 100%;
    @include sp {
    }
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 2;
    margin: 0 0 40px;
    letter-spacing: 0.12em;
    @include sp {
      line-height: 1.6;
      margin-bottom: 25px;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }
  &--description {
    font-size: 16px;
    line-height: 2.375;
    @include sp {
      font-size: 15px;
      line-height: 28px;
    }
  }
  &--modal_link {
    color: #4b94af;
    font-family: $ff-noto_serif;
    font-size: 16px;
    text-decoration: none;
    display: block;
    text-align: center;
    max-width: 380px;
    line-height: 1.5;
    padding: 18px 0;
    border: 1px solid #4b94af;
    border-radius: 5px;
    margin: 32px auto 0;
    position: relative;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      border-top: 1px solid #4b94af;
      border-right: 1px solid #4b94af;
      transform: rotate(45deg) translateY(-50%);
      top: calc(50%);
      right: 20px;
    }
    @include sp {
      width: auto;
      max-width: 325px;
      margin-top: 20px;
    }
  }
}

.wakana_about_content_modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: z(wakana_about_content_modal);
  background: rgba(0, 0, 0, 0.75);
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  pointer-events: none;
  transform: scale(0);
  visibility: hidden;
  transition: 0.2s;
  &.js-show {
    visibility: visible;
    transform: scale(1);
    pointer-events: auto;
  }
  &--inner {
    background-color: #fff;
    max-width: 750px;
    padding: 20px;
    margin: 0 0 30px;
    @include sp {
      padding-top: 40px;
      padding-bottom: 30px;
    }
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0.12em;
    margin: 0 0 36px;
    text-align: center;
    @include sp {
      font-size: 15px;
      line-height: 1.5;
    }
  }
  &--img {
    max-width: 100%;
    display: block;
    margin: 0 auto 24px;
  }
  &--description {
    font-size: 16px;
    line-height: 1.625;
    @include sp {
      font-size: 15px;
    }
  }
  &--close_btn {
    font-size: 12px;
    color: #fff;
    background-color: #333333;
    width: 60px;
    height: 60px;
    position: relative;
    padding-top: 40px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    &:before, &:after {
      position: absolute;
      content: '';
      width: 1px;
      height: 28px;
      transform-origin: center;
      background: #fff;
      left: 50%;
      top: calc(50% - 20px);
    }
    &:before {
      transform: rotate(45deg) translate(-50%);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.wakana-contact_area {
  background-color: $color-gray_d8d8d8;
  position: relative;
  padding: 40px 0;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: calc(50% - 1px);
    top: 0;
    background-color: #fff;
  }
  @include sp {
    padding: 30px 20px;
    &:before {
      display: none;
    }
  }
  &--inner {
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }
  }
  &--box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(50% - 70px);
    @include sp {
      width: auto;
      display: block;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &--lead {
    font-family: $ff-noto_serif;
    font-size: 18px;
    margin: 0 0 10px;
    @include sp {
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
  &--tel {
    font-family: $ff-noto_serif;
    font-size: 24px;
    margin: 0 0 8px;
    @include sp {
      text-align: center;
    }
  }
  &--small {
    display: block;
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin: 0 0 24px;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      font-size: 12px;
      text-align: center;
    }
  }
  &--link_flow {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    color: #6692ab;
    background-color: #fff;
    border: 1px solid #6692ab;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #6692ab;
      border-right: 1px solid #6692ab;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin: 0 auto;
    }
  }
  &--link_book {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    color: #fff;
    background-color: #6692ab;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin: 0 auto;
    }
  }
  &--annotate {
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin: 0 0 18px;
    @include sp {
      line-height: 1.42;
      margin-bottom: 10px;
    }
  }
}

.wakana-contact {
  background-color: #d8d8d8;
  padding: 40px 0;
  border-top: 2px solid #fff;
  @include sp {
    padding: 30px 20px;
    border-top: none;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #fff;
      left: 20px;
      top: 0;
    }
  }
  &--ttl {
    font-family: $ff-noto_serif;
    font-size: 18px;
    letter-spacing: 0.15em;
    text-align: center;
    font-weight: normal;
  }
  &--inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 40px;
    margin-top: 20px;
    @include sp {
      display: block;
      margin-top: 10px;
    }
  }
  &--content {
    min-width: 260px;
    @include sp {
      min-width: inherit;
    }
  }
  &--tel {
    font-family: $ff-noto_serif;
    font-size: 24px;
    @include sp {
      text-align: center;
    }
  }
  &--business_hour {
    font-family: $ff-noto_serif;
    font-size: 14px;
    margin-top: 8px;
    @include sp {
      text-align: center;
    }
  }
  &--link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    height: 48px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 14px;
    margin-top: auto;
    background-color: #fff;
    color: #333;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #333;
      border-right: 1px solid #333;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      margin-top: 24px;
    }
  }
}

.wakana-think {
  padding: 110px 0 40px;
  position: relative;
  z-index: 0;
  @include sp {
    padding: 50px 20px 20px;
    overflow: hidden;
  }
  &--ttl {
    display: block;
    position: relative;
    margin: 0 0 120px;
    &:before, &:after {
      display: block;
      position: absolute;
      content: url('/images/wakana_about/wakana-think--ttl-bgimg.png');
      z-index: 1;
    }
    &:before {
      left: calc(50% - 380px);
      top: -120px;
    }
    &:after {
      left: calc(50% + 150px);
      top: -50px;
      transform: rotate(180deg);
    }
    @include sp {
      &:before {
        left: calc(50% - 340px)
      }
      &:after {
        left: calc(50% + 100px);
      }
      margin-bottom: 60px;
    }
  }
  &--ttl_txt {
    color: #4b94af;
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 32px;
    letter-spacing: 0.2em;
    display: block;
    margin: 0 auto 16px;
    text-align: center;
    position: relative;
    z-index: 2;
    @include sp {
      font-size: 22px;
      letter-spacing: 0.05em;
    }
  }
  &--ttl_lead {
    color: #4b94af;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before, &:after {
      content: '';
      display: block;
      width: 22px;
      height: 1px;
      background-color: #4b94af;
      margin: 0 15px;
    }
    @include sp {
      font-size: 15px;
      &:before, &:after {
        margin: 0 10px;
      }
    }
  }
  &--review {
    font-size: 16px;
    line-height: 2.375;
    font-feature-settings: "palt";
    max-width: 980px;
    margin: 0 auto;
    padding: 40px 0 40px 20px;
    border: 1px solid #9dc8d0;
    position: relative;
    z-index: 2;
    &:before, &:after {
      position: absolute;
      content: '';
      display: block;
      width: 3px;
      height: 16px;
      background-color: #fff;
    }
    &:before {
      bottom: 0;
      left: -1px;
    }
    &:after {
      top: 0;
      right: -1px;
    }
    @include sp {
      padding: 20px;
      font-size: 15px;
      line-height: 28px;
    }
  }
}
.wakana-think_content {
  max-width: 980px;
  margin: 0 auto 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  &:nth-of-type(1) {
    padding-bottom: 40px;
    border-bottom: 1px solid #c5c5c5;
  }
  &:nth-of-type(even) {
    flex-direction: row-reverse;
    &:before {
      left: -200px;
    }
  }
  &:nth-of-type(odd) {
    &:before {
      right: -200px;
    }
  }
  &:nth-of-type(n+2) {
    &:before {
      position: absolute;
      content: url('/images/wakana_about/wakana-think_content-bgimg.png');
      top: -300px;
      z-index: 1;
    }
  }
  @include sp {
    display: block;
    margin-bottom: 50px;
    &:nth-of-type(1) {
      padding-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  &--box {
    width: calc(50% - 30px);
    position: relative;
    z-index: 2;
    @include sp {
      width: auto;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--img {
    max-width: 100%;
  }
  &--comment {
    color: #868686;
    font-size: 14px;
    margin-top: 10px;
  }
  &--ttl {
    color: #4b94af;
    font-family: $ff-noto_serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.12em;
    margin: 0 0 30px;
    position: relative;
    &:before {
      position: absolute;
      content: url("/images/wakana_about/wakana-think_content--ttl-icon.png");
      left: -38px;
      top: -20px;
    }
    @include sp {
      margin-bottom: 20px;
      letter-spacing: 0.05em;
    }
  }
  &--description {
    font-size: 16px;
    line-height: 2.375;
    &-nottl {
      margin-top: 68px;
    }
    @include sp {
      font-size: 15px;
      line-height: 28px;
      &-nottl {
        margin-top: 0;
      }
    }
  }
}

.wakana-about_present {
  display: flex;
  justify-content: center;
  background: url('/images/wakana_about/wakana-about_present-border.png') no-repeat center bottom;
  padding-bottom: 40px;
  margin-bottom: 90px;
  @include sp {
    margin-bottom: 40px;
  }
}

.wakana-faq_button {
  margin: 0 auto;
  padding: 68px 0 0;
  @include sp {
    padding: 40px 20px 0;
  }
  &--link {
    font-family: $ff-noto_serif;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    width: 368px;
    height: 68px;
    border: 1px solid;
    text-decoration: none;
    position: relative;
    transition: 0.2s;
    font-size: 18px;
    background-color: #fff;
    color: #333;
    margin: 0 auto;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      border-top: 1px solid #333;
      border-right: 1px solid #333;
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      width: 100%;
    }
  }
}